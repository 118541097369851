 <template>
    <div class="rating_page">
        <section class="profile-info">
            <section
                class="headportrait"
                @click="openModel('type')"
                style="border-top: none"
            >
                <h2>请选择您的身份</h2>
                <div class="headportrait-div">
                    <p>{{ userInfo.type }}</p>
                    <span class="headportrait-div-bottom">
                        <svg fill="#666666">
                            <use
                                xmlns:xlink="http://www.w3.org/1999/xlink"
                                xlink:href="#arrow-right"
                            ></use>
                        </svg>
                    </span>
                </div>
            </section>
            <section
                class="headportrait headportraitwo"
                @click="openModel('address')"
                v-if="userInfo.type == '市民'"
            >
                <h2>请选择所属街道</h2>
                <div class="headportrait-div">
                    <p>{{ userInfo.address }}</p>
                    <span class="headportrait-div-bottom">
                        <svg fill="#666666">
                            <use
                                xmlns:xlink="http://www.w3.org/1999/xlink"
                                xlink:href="#arrow-right"
                            ></use>
                        </svg>
                    </span>
                </div>
            </section>
            <div v-if="userInfo.type == '学生'">
                <section
                    class="headportrait headportraitwo"
                    @click="openModel('grade')"
                >
                    <h2>请选择年级</h2>
                    <div class="headportrait-div">
                        <p>{{ userInfo.grade }}</p>
                        <span class="headportrait-div-bottom">
                            <svg fill="#666666">
                                <use
                                    xmlns:xlink="http://www.w3.org/1999/xlink"
                                    xlink:href="#arrow-right"
                                ></use>
                            </svg>
                        </span>
                    </div>
                </section>
                <section
                    class="headportrait headportraitwo"
                    @click="openModel('school')"
                >
                    <h2>请选择学校</h2>
                    <div class="headportrait-div">
                        <p>{{ userInfo.school }}</p>
                        <span class="headportrait-div-bottom">
                            <svg fill="#666666">
                                <use
                                    xmlns:xlink="http://www.w3.org/1999/xlink"
                                    xlink:href="#arrow-right"
                                ></use>
                            </svg>
                        </span>
                    </div>
                </section>
            </div>

            <router-link
                to="/profile/info/setusername?modify=phone"
                class="info-router"
            >
                <section class="info-router">
                    <section class="headportrait headportraitwo">
                        <h2>手机号</h2>
                        <div class="headportrait-div">
                            <p>{{ userInfo.phone }}</p>
                            <span class="headportrait-div-bottom">
                                <svg fill="#666666">
                                    <use
                                        xmlns:xlink="http://www.w3.org/1999/xlink"
                                        xlink:href="#arrow-right"
                                    ></use>
                                </svg>
                            </span>
                        </div>
                    </section>
                </section>
            </router-link>
            <router-link
                to="/profile/info/setusername?modify=username"
                class="info-router"
            >
                <section class="headportrait headportraitwo">
                    <h2>用户名</h2>
                    <div class="headportrait-div">
                        <p>{{ userInfo.username }}</p>
                        <span class="headportrait-div-bottom">
                            <svg fill="#666666">
                                <use
                                    xmlns:xlink="http://www.w3.org/1999/xlink"
                                    xlink:href="#arrow-right"
                                ></use>
                            </svg>
                        </span>
                    </div>
                </section>
            </router-link>
            <section class="headportrait headportraitwo headportraithree">
                <input
                    type="file"
                    class="profileinfopanel-upload"
                    @change="uploadAvatar"
                />
                <h2>头像</h2>
                <div class="headportrait-div">
                    <img
                        v-if="userInfo"
                        :src="userInfo.avatar"
                        class="headportrait-avatar"
                    />
                    <span class="headportrait-div-top" v-else>
                        <svg>
                            <use
                                xmlns:xlink="http://www.w3.org/1999/xlink"
                                xlink:href="#avatar-default"
                            ></use>
                        </svg>
                    </span>
                    <span class="headportrait-div-bottom">
                        <svg fill="#666666">
                            <use
                                xmlns:xlink="http://www.w3.org/1999/xlink"
                                xlink:href="#arrow-right"
                            ></use>
                        </svg>
                    </span>
                </div>
            </section>
        </section>

        <alert-tip
            v-if="showAlert"
            @closeTip="showAlert = false"
            :alertText="alertText"
        ></alert-tip>
        <transition name="router-slid" mode="out-in">
            <router-view></router-view>
        </transition>

        <van-popup v-model="showPicker" round position="bottom">
            <van-picker
                show-toolbar
                :columns="columns"
                @cancel="showPicker = false"
                @confirm="(value) => onConfirm(current, value)"
            />
        </van-popup>
    </div>
</template>

<script>
import { mapMutations, mapState } from "vuex";
import headTop from "@/components/header/head";
import { setUserSetup } from "@/service";
import alertTip from "@/components/common/alertTip";
import { getImgPath } from "@/components/common/mixin";
import { imgBaseUrl } from "@/config/env";
import { removeStore } from "@/config/mUtils";

export default {
    data() {
        return {
            current: "",
            type: ["学生", "市民"],
            address: [
                "南京东路街道",
                "外滩街道",
                "瑞金二路街道",
                "淮海中路街道",
                "豫园街道",
                "打浦桥街道",
                "老西门街道",
                "小东门街道",
                "五里桥街道",
                "半淞园路街道",
                "其他",
            ],
            grade: ["小学", "初中", "高中"],
            school: [
                {
                    text: "小学",
                    children: [
                        {
                            text: "上海市黄浦区第一中心小学",
                        },
                        {
                            text: "上海市实验小学",
                        },
                        {
                            text: "上海市黄浦区北京东路小学",
                        },

                        {
                            text: "上海市黄浦区蓬莱路第二小学",
                        },
                        {
                            text: "上海师范专科学校附属小学",
                        },
                        {
                            text: "上海市黄浦区卢湾二中心小学",
                        },
                        {
                            text: "上海市黄浦区复兴东路第三小学",
                        },
                        {
                            text: "上海市黄浦区卢湾三中心小学",
                        },
                        {
                            text: "上海市裘锦秋实验学校",
                        },
                        {
                            text: "上海市黄浦区曹光彪小学",
                        },
                        {
                            text: "上外一黄浦外国语小学",
                        },

                        {
                            text: "上海师范大学附属卢湾实验小学",
                        },
                        {
                            text: "上海市黄浦区四川南路小学",
                        },
                        {
                            text: "上海市黄浦区报童小学",
                        },
                        {
                            text: "上海市黄浦区重庆北路小学",
                        },

                        {
                            text: "上海市黄浦区梅溪小学",
                        },
                        {
                            text: "上海汇黄浦区回民小学",
                        },
                        {
                            text: "上海市黄浦区董家渡第二小学",
                        },
                        {
                            text: "上海市黄浦区新凌小学",
                        },
                        {
                            text: "上海市七色花小学",
                        },
                        {
                            text: "上海市黄浦区淮海中路小学",
                        },
                        {
                            text: "上海市黄浦区海华小学",
                        },
                        {
                            text: "上海市黄浦区巨鹿路第一小学",
                        },
                        {
                            text: "上海市黄浦区徽宁路第三小学",
                        },
                        {
                            text: "上海市黄浦区卢湾一中心小学",
                        },
                        {
                            text: "上海市黄浦区中华路第三小学",
                        },
                        {
                            text: "上海市黄浦学校",
                        },
                        {
                            text: "上海市黄浦区教育学院附属中山学校",
                        },
                        {
                            text: "上海市民办永昌学校",
                        },
                        {
                            text: "康德双语实验学校",
                        },
                    ],
                },
                {
                    text: "初中",
                    children: [
                        {
                            text: "上海市格致初级中学",
                        },
                        {
                            text: "上海市大同初级中学",
                        },
                        {
                            text: "上海外国语大学附属大境初级中学",
                        },
                        {
                            text: "上海市光明初级中学",
                        },

                        {
                            text: "上海市敬业初级中学",
                        },
                        {
                            text: "上海市向明初级中学",
                        },
                        {
                            text: "上海市市八初级中学",
                        },
                        {
                            text: "上海市尚文中学",
                        },
                        {
                            text: "上海市卢湾中学",
                        },
                        {
                            text: "上海市兴业中学",
                        },
                        {
                            text: "上海市清华中学",
                        },
                        {
                            text: "上海市市八初级中学",
                        },

                        {
                            text: "上海市民办明珠中学",
                        },
                        {
                            text: "上海市震旦外国语中学",
                        },
                        {
                            text: "上海市民办立达中学",
                        },
                        {
                            text: "上海市黄浦学校",
                        },
                        {
                            text: "上海市黄浦区教育学院附属中山学校",
                        },
                        {
                            text: "上海市民办永昌学校",
                        },
                        {
                            text: "康德双语实验学校",
                        },
                        {
                            text: "上海市第十中学",
                        },
                        {
                            text: "上海理工大学附属储能中学",
                        },
                        {
                            text: "上海市金陵中学",
                        },
                        {
                            text: "上海市同济黄浦设计创意中学",
                        },
                        {
                            text: "上海市市南中学",
                        },
                        {
                            text: "上海市比乐中学",
                        },
                    ],
                },
                {
                    text: "高中",
                    children: [
                        {
                            text: "上海市格致中学",
                        },
                        {
                            text: "上海市大同中学",
                        },
                        {
                            text: "上海外国语大学附属大境中学",
                        },
                        {
                            text: "上海市光明中学",
                        },
                        {
                            text: "上海市敬业中学",
                        },

                        {
                            text: "上海市卢湾高级中学",
                        },
                        {
                            text: "上海市第八中学",
                        },
                        {
                            text: "上海市五爱高级中学",
                        },
                        {
                            text: "上海市向明中学",
                        },
                        {
                            text: "上海市第十中学",
                        },
                        {
                            text: "上海理工大学附属储能中学",
                        },
                        {
                            text: "上海市金陵中学",
                        },
                        {
                            text: "上海市同济黄浦设计创意中学",
                        },
                        {
                            text: "上海市市南中学",
                        },
                        {
                            text: "上海市比乐中学",
                        },
                    ],
                },
                {
                    text: "职业学校",
                    children: [
                        {
                            text: "中华职业学校",
                        },
                        {
                            text: "上海市商贸旅游学校",
                        },
                    ],
                },
            ],
            form: {
                type: "",
                address: "",
                grade: "",
                school: "",
            },

            username: "", //用户名
            resetname: "", //重置用户名
            infotel: "", //用户手机
            avatar: "", //用户头像
            show: false, //显示提示框
            isEnter: true, //是否登录
            isLeave: false, //是否退出
            showAlert: false,
            alertText: null,
            imgBaseUrl,
            value: "",
            showPicker: false,
            columns: [
                "杭州",
                "宁波",
                "温州",
                "绍兴",
                "湖州",
                "嘉兴",
                "金华",
                "衢州",
            ],
        };
    },
    beforeDestroy() {
        clearTimeout(this.timer);
    },
    components: {
        headTop,
        alertTip,
    },
    mixins: [getImgPath],
    computed: {
        ...mapState(["userInfo", "imgPath"]),
    },
    methods: {
        ...mapMutations(["OUT_LOGIN", "SAVE_AVANDER"]),

        exitlogin() {
            this.show = true;
            this.isEnter = true;
            this.isLeave = false;
        },
        waitingThing() {
            //取消推出
            clearTimeout(this.timer);
            this.isEnter = false;
            this.isLeave = true;
            this.timer = setTimeout(() => {
                clearTimeout(this.timer);
                this.show = false;
            }, 200);
        },
        async onConfirm(current, value) {
            const params = {
                [current]: current == "school" ? value[1] : value,
            };
            const { data } = await setUserSetup(params);
            this.userInfo[current] = current == "school" ? value[1] : value;
            this.showPicker = false;
        },
        //退出登录
        async outLogin() {
            this.OUT_LOGIN();
            this.waitingThing();
            this.$router.go(-1);
            removeStore("user_id");
        },
        changePhone() {
            this.showAlert = true;
            this.alertText = "请在手机APP中设置";
        },
        async uploadAvatar() {
            //上传头像
            if (this.userInfo) {
                const sessionId = sessionStorage.getItem("xxl_sso_sessionid");
                let input = document.querySelector(".profileinfopanel-upload");
                let data = new FormData();
                data.append("file", input.files[0]);
                try {
                    let response = await fetch("/api/others/upload", {
                        method: "POST",
                        credentials: "include",
                        body: data,
                        headers: {
                            xxl_sso_sessionid: sessionId,
                        },
                    });
                    let res = await response.json();
                    await this.onConfirm("avatar", res.msg);
                    this.userInfo.avatar = res.msg;
                } catch (error) {
                    this.showAlert = true;
                    this.alertText = "上传失败";
                    throw new Error(error);
                }
            }
        },
        openModel(name) {
            this.current = name;
            this.columns = this[name];
            this.$nextTick(() => {
                this.showPicker = true;
            });
        },
    },
    watch: {
        userInfo: function (value) {
            console.log(this.userInfo);
            if (value && value.user_id) {
                this.username = value.username;
                this.infotel = value.mobile || value.phone;
                this.avatar = value.avatar;
            }
        },
    },
};
</script>

<style lang="scss" scoped>
@import "@/style/mixin.scss";

.rating_page {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #f2f2f2;
    z-index: 202;
    p,
    span {
        font-family: Helvetica Neue, Tahoma, Arial;
    }
}
.profile-info {
    padding: 0 40px;
    background: #fff;
    .profileinfopanel-upload {
        display: block;
        position: absolute;
        opacity: 0;
        /* top: 2.35rem; */
        left: 0;
    }
    .headportrait {
        height: 120px;
        @include fj(space-between);
        align-items: center;
        border-top: 1px solid #ddd;
        background: #fff;
        color: #231f20;
        h2 {
            @include sc(28px, #231f20);
            display: flex;
            align-items: center;
        }
        .headportrait-div {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            font-size: 26px;
            p {
                margin-right: 20px;
            }
            span {
                display: inline-block;

                svg {
                    @include wh(100%, 100%);
                }
            }
            .headportrait-div-bottom {
                @include wh(24px, 48px);
                color: #666666;
            }
        }
    }
    .headportraitwo {
        margin-top: 0;
    }
    .headportraithree {
        border-bottom: 1px solid #ddd;
    }
    .bind-phone {
        padding: 0.4rem 0.4rem;
        @include sc(0.5rem, #666);
    }
    .headportrait-avatar {
        width: 60px;
        height: 60px;
        border-radius: 50%;
        margin-right: 30px;
    }
    .exitlogin {
        width: 96%;
        margin: 1.3rem auto 0;
        line-height: 1.5rem;
        @include borderRadius(5px);
        text-align: center;
        background: #d8584a;
        @include sc(0.6rem, #fff);
    }
    .exitlogin:active {
        opacity: 0.8;
        background: #c1c1c1;
    }
}
.info-router {
    display: block;
}
.coverpart {
    @include wh(100%, 100%);
    @include allcover;
    .cover-background {
        @include wh(100%, 100%);
        @include allcover;
        background: #000;
        z-index: 100;
        opacity: 0.2;
    }
    .cover-content {
        width: 94%;
        background: $fc;
        padding: 17px;
        position: absolute;
        top: 20%;
        left: 3%;
        z-index: 1000;
        @include borderRadius(5px);
        .sa-icon {
            @include wh(90px, 90px);
            border: 4px solid #f8bb86;
            @include borderRadius(50%);
            margin: 20px auto;
            position: relative;
            .sa-body {
                @include wh(5px, 47px);
                @include borderRadius(2px);
                background: #f8bb86;
                @include cl;
                top: 10px;
            }
            .sa-dot {
                @include wh(7px, 7px);
                @include borderRadius(50%);
                background: #f8bb86;
                @include cl;
                bottom: 10px;
            }
        }
        h2 {
            width: 100%;
            text-align: center;
            @include sc(30px, #575757);
            font-weight: 500;
            margin: 25px 0;
        }
        .sa-botton {
            width: 100%;
            text-align: center;

            button {
                display: inline-block;
                padding: 0.4rem 1rem;
                @include borderRadius(5px);
                @include sc(0.6rem, $fc);
                letter-spacing: 1px;
                margin-top: 26px;
            }
            .waiting {
                background: #c1c1c1;
                margin-right: 0.4rem;
            }
            .quitlogin {
                background: rgb(221, 107, 85);
            }
        }
    }
}
@-webkit-keyframes bounceIn {
    from,
    20%,
    40%,
    60%,
    80%,
    100% {
        -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
        animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    }

    0% {
        opacity: 0;
        -webkit-transform: scale3d(0.3, 0.3, 0.3);
        transform: scale3d(0.3, 0.3, 0.3);
    }

    20% {
        -webkit-transform: scale3d(1.1, 1.1, 1.1);
        transform: scale3d(1.1, 1.1, 1.1);
    }

    40% {
        -webkit-transform: scale3d(0.9, 0.9, 0.9);
        transform: scale3d(0.9, 0.9, 0.9);
    }

    60% {
        opacity: 1;
        -webkit-transform: scale3d(1.03, 1.03, 1.03);
        transform: scale3d(1.03, 1.03, 1.03);
    }

    80% {
        -webkit-transform: scale3d(0.97, 0.97, 0.97);
        transform: scale3d(0.97, 0.97, 0.97);
    }

    100% {
        opacity: 1;
        -webkit-transform: scale3d(1, 1, 1);
        transform: scale3d(1, 1, 1);
    }
}

@keyframes bounceIn {
    from,
    20%,
    40%,
    60%,
    80%,
    100% {
        -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
        animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    }

    0% {
        opacity: 0;
        -webkit-transform: scale3d(0.3, 0.3, 0.3);
        transform: scale3d(0.3, 0.3, 0.3);
    }

    20% {
        -webkit-transform: scale3d(1.1, 1.1, 1.1);
        transform: scale3d(1.1, 1.1, 1.1);
    }

    40% {
        -webkit-transform: scale3d(0.9, 0.9, 0.9);
        transform: scale3d(0.9, 0.9, 0.9);
    }

    60% {
        opacity: 1;
        -webkit-transform: scale3d(1.03, 1.03, 1.03);
        transform: scale3d(1.03, 1.03, 1.03);
    }

    80% {
        -webkit-transform: scale3d(0.97, 0.97, 0.97);
        transform: scale3d(0.97, 0.97, 0.97);
    }

    100% {
        opacity: 1;
        -webkit-transform: scale3d(1, 1, 1);
        transform: scale3d(1, 1, 1);
    }
}
@-webkit-keyframes zoomOut {
    from {
        opacity: 1;
    }

    50% {
        opacity: 0;
        -webkit-transform: scale3d(0.3, 0.3, 0.3);
        transform: scale3d(0.3, 0.3, 0.3);
    }

    100% {
        opacity: 0;
    }
}

@keyframes zoomOut {
    from {
        opacity: 1;
    }

    50% {
        opacity: 0;
        -webkit-transform: scale3d(0.3, 0.3, 0.3);
        transform: scale3d(0.3, 0.3, 0.3);
    }

    100% {
        opacity: 0;
    }
}
body .coverpart .cover-animate {
    transition: all 1s;
    animation: bounceIn 0.6s;
}
body .coverpart .cover-animate-leave {
    animation: zoomOut 0.4s;
}
.router-slid-enter-active,
.router-slid-leave-active {
    transition: all 0.4s;
}
.router-slid-enter,
.router-slid-leave-active {
    transform: translate3d(2rem, 0, 0);
    opacity: 0;
}
</style>
